import Vue from "vue";
import App from "./App.vue";
import "@/style/element-#EF2E22/index.css";
import ElementUI from "element-ui";
import ElementLocale from "element-ui/lib/locale";

import router from "./router";
import store from "@/state/store";
import i18n from "./i18n";
import * as echarts from "echarts";
import moment from "moment";

import axios from "axios";
import Clipboard from "v-clipboard";
import VueQuillEditor from "vue-quill-editor";
import { makeRoutes } from "@/utils/";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "@/css/iconfont.css";
import DefaultLayout from "./views/layout";
import EmptyLayout from "./views/empty-layout";
import * as utils from "@/utils";
Vue.prototype.$utils = utils;
import htmlToPdf from '@/utils/htmlToPdf'
Vue.use(htmlToPdf)

Vue.use(VueQuillEditor);
Vue.use(Clipboard);
Vue.prototype.$axios = axios;
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;
Vue.use(ElementUI);

ElementLocale.i18n((key, value) => i18n.t(key, value));

Vue.component("default-layout", DefaultLayout);
Vue.component("empty-layout", EmptyLayout);

import "@/css/common.css";
// import "element-ui/lib/theme-chalk/index.css";

// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-inner-declarations
function redirectToLogin(next, routeTo) {
  // Pass the original route to the login component
//   console.log("redirectToLogin:", next, routeTo);
  next({ name: "userLogin", query: { redirectFrom: routeTo.fullPath } });
}
let newRoutes; //这里有问题
// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
    // Check if auth is required on this route
    // (including nested routes).
    const authRequired = routeTo.matched.some(
      (route) => route.meta.authRequired
    );

    // If auth isn't required for the route, just continue.
    if (!authRequired) return next();

    // If auth is required and the user is logged in...
    if (store.getters["auth/loggedIn"]) {
      // Validate the local user token...
      return store.dispatch("auth/validate").then((validUser) => {
        // Then continue if the token still represents a valid user,
        // otherwise redirect to login.
        validUser ? next() : redirectToLogin(next, routeTo);
      });
    }

    // If auth is required and the user is NOT currently logged in,
    // redirect to login.
    redirectToLogin(next, routeTo);
  } else {
    const publicPages = [
      "/v2/login",
      "/v2/forget-password",
      "/v2/register",
      "/shop/resident",
      "/",
    ];
    const authpage = !publicPages.includes(routeTo.path);
    const loggeduser = localStorage.getItem("vendor_user");
    const routesJson = localStorage.getItem("routes");

    // console.log("routeTo0:", routeTo, authpage, !loggeduser);
    if (authpage && !loggeduser) {
      return next("/v2/login");
    }

    if (authpage && !routesJson) {
      redirectToLogin(next, routeTo);
    }

    // console.log('newRoutes:', newRoutes, routesJson);

    if (!newRoutes) {
      //不加这个判断，路由会陷入死循环
      // console.log("routesJson:", routesJson);
      if (routesJson) {
        try {
          const newMenu = JSON.parse(routesJson);
          newRoutes = makeRoutes(newMenu);
          // console.log("newMenu:", newMenu);
          router.options.routes = newMenu;
          // console.log("newRoutes:", newRoutes);
          router.addRoutes(newRoutes);
        //   console.log("routeTo1:", routeTo);
          return next(routeTo, next);
        } catch (error) {
          // console.log("newRoutesJson:", error);
        }
      } else {
        // console.log("routeTo2:", routeTo);
        next();
      }
    } else {
      next();
    }
  }
});
// router.beforeEach((to, from, next) => {
//   console.log(to, from);
//   if (!newRoutes) {
//     //不加这个判断，路由会陷入死循环
//     const newRoutesJson = localStorage.getItem("routes");
//     // console.log("newRoutesJson:", newRoutesJson);
//     if (newRoutesJson) {
//       try {
//         newRoutes = JSON.parse(newRoutesJson);
//         newRoutes = newRoutes.map(({menu}) => {
//           console.log(menu);
//           return {
//             ...menu,
//             path: menu.path,
//             name: menu.name,
//             component: AsyncImport(menu),
//             meta: {
//               layout: "default-layout",
//             },
//           };
//         });
//         // console.log("newRoutes:", newRoutes);
//         router.options.routes = newRoutes;
//         router.addRoutes(newRoutes);
//         next({ ...to, replace: true });
//       } catch (error) {
//         console.log("newRoutesJson:", error);
//       }
//     }
//   } else {
//     next();
//   }
// });

window.vm = new Vue({
  router,
  store,
  i18n,
  moment,
  render: (h) => h(App),
}).$mount("#app");
