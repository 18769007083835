import request from "./request.js";
import { formatQuery } from "./index";

// 搜索
export function HomeSearch(params) {
    return request({
        url: `/product/search?${formatQuery(params)}`,
        method: "GET",
        auth: 0,
    });
}

// 购物车
export function GetCart(params) {
    return request({
        url: `/cart/v2?${formatQuery(params)}`,
        method: "GET",
        auth: 1,
    });
}

// 获取权限路由菜单
export function GetMenuList(params) {
    return request({
        url: `user/getMenuList`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 添加Banner图片
export function AddBanner(params) {
    return request({
        url: `addBannerbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 编辑Banner图片
export function EditBanner(params) {
    return request({
        url: `editBannerbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 删除Banner图片
export function DelBanner(params) {
    return request({
        url: `delBannerbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取Banner图片列表
export function GetBannerList(params) {
    return request({
        url: `getBannerListbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 添加分类
export function AddCategory(params) {
    return request({
        url: `addModCategorybyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 编辑分类
export function EditCategory(params) {
    return request({
        url: `editModCategorybyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 删除分类
export function DelCategory(params) {
    return request({
        url: `delModCategorybyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 删除商品
export function DelProduct(params) {
    return request({
        url: `delSpubyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取分类列表
export function GetCategoryList(params) {
    return request({
        url: `getModCategoryListbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取微信支付二维码字符串
export function GetWechatPay(params) {
    return request({
        url: `getPayCodebyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取微信支付结果
export function GetPayResult(params) {
    return request({
        url: `getPaybyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 添加银行卡
export function AddBankCard(params) {
    return request({
        url: `addBankCardbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 编辑银行卡
export function EditBankCard(params) {
    return request({
        url: `editBankCardbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 删除银行卡
export function DelBankCard(params) {
    return request({
        url: `delBankCardbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取银行卡列表
export function GetBankCardList(params) {
    return request({
        url: `getBankCardListbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 添加货运定价
export function AddLogisticsPrice(params) {
    return request({
        url: `addLogisticsPricebyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 编辑货运定价
export function EditLogisticsPrice(params) {
    return request({
        url: `editLogisticsPricebyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取物流详情
export function GetLogisticsPrice(params) {
    return request({
        url: `getLogisticsPricebyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}
// 删除货运定价
export function DelLogisticsPrice(params) {
    return request({
        url: `delLogisticsPricebyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取货运定价
export function GetLogisticsPriceList(params) {
    return request({
        url: `getLogisticsPriceListbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取订单列表
export function GetOrderList(params) {
    return request({
        url: `getOrderListbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取订单列表
export function GetOrderDetail(params) {
    return request({
        url: `getOrderDetailbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 取消订单
export function CanceOrder(params) {
    return request({
        url: `canceOrderbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 确认订单
export function confirmeOrder(params) {
    return request({
        url: `updateOrderbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 备货完成，上传货物照片、产品合格证等
export function GoodsReady(params) {
    return request({
        url: `stockAttachbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取版本号
export function GetAppVersionList(params) {
    return request({
        url: `getAppVersionList`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 添加汇率
export function AddExchateRate(params) {
    return request({
        url: `addExchateRatebyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}


// 获取汇率列表
export function GetExchateRateList(params) {
    return request({
        url: `getExchateRateListbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 修改汇率
export function EditExchateRate(params) {
    return request({
        url: `editExchateRatebyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}


// 删除汇率
export function DelExchateRate(params) {
    return request({
        url: `delExchateRatebyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 添加品牌
export function AddBrand(params) {
    return request({
        url: `addBrandbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}


// 获取品牌列表
export function GetBrandList(params) {
    return request({
        url: `getBrandListbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 修改品牌
export function EditBrand(params) {
    return request({
        url: `editBrandbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}


// 删除品牌
export function DelBrand(params) {
    return request({
        url: `delBrandbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取专属分类列表
export function GetMyCategoryList(params) {
    return request({
        url: `getCategoryListbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 修改产品
export function EditSpu(params) {
    return request({
        url: `editSpubyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 新建产品
export function AddSpu(params) {
    return request({
        url: `addSpubyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 互为附属商品
export function EditSpuAddedIdsbyVendor(params) {
    return request({
        url: `editSpuAddedIdsbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取产品列表
export function GetSpuList(params) {
    return request({
        url: `getSpuListbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取附属产品列表
export function GetSpuListByIds(params) {
    return request({
        url: `getSpuListByIds`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取物流列表
export function GetOrderLogisticListbyVendor(params) {
    return request({
        url: `getOrderLogisticListbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取物流列表
export function GetOrderLogisticDetailbyVendor(params) {
    return request({
        url: `getOrderLogisticDetailbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 编辑物流信息
export function EditOrderLogisticbyVendor(params) {
    return request({
        url: `editOrderLogisticbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取产品详情
export function GetSpu(params) {
    return request({
        url: `getSpubyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取规格
export function GetSpecList(params) {
    return request({
        url: `getSpecListbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 编辑规格
export function EditSpec(params) {
    return request({
        url: `upsertSpecListbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 编辑sku
export function EditSku(params) {
    return request({
        url: `upsertSkuListbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取sku列表
export function GetSkuList(params) {
    return request({
        url: `getSkuListbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取阶梯列表
export function GetLadderList(params) {
    return request({
        url: `getSkuLadderListbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 修改阶梯列表
export function EditLadder(params) {
    return request({
        url: `upsertSkuLadderbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取标签列表
export function GetTagList(params) {
    return request({
        url: `getTagbyBindId`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 添加标签
export function AddTag(params) {
    return request({
        url: `addTagbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}


// 添加标签
export function DelTagRealtion(params) {
    return request({
        url: `delTagRealtionbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}


// 新建店铺
export function AddStore(params) {
    return request({
        url: `addStorebyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}


// 获取店铺信息
export function getStore(params) {
    return request({
        url: `getStorebyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}


// 修改店铺信息
export function editStore(params) {
    return request({
        url: `editStorebyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 修改用户信息
export function editUser(params) {
    return request({
        url: `editUserbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    });
}


// 获取用户信息
export function getUserDetail(params) {
    return request({
        url: `getUserDetailbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    });
}


// 获取视频列表
export function GetVodList(params) {
    return request({
        url: `getVodListByIdList`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 登录
export function Login(params) {
    return request({
        url: `loginbyVendor`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 注册
export function Registger(params) {
    return request({
        url: `registger`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 忘记密码
export function Forget(params) {
    return request({
        url: `forgetbyVendor`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 新建验证码
export function AddVerifyCode(params) {
    return request({
        url: `addVerifyCode`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 获取国家列表
export function GetCountryList(params) {
    return request({
        url: `getCountryList`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 获取分类列表
export function GetCategoryLists(params) {
    return request({
        url: `getCategoryListbyVendor`,
        method: "POST",
        data: params,
        auth: 1,
    });
}


// 获取分类列表(第一级的分类)
export function GetMainCategoryList(params) {
    return request({
        url: `getCategoryList`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 获取oss直传凭证
export function GetOssPolicyToken(params) {
    return request({
        url: `getOssPolicyToken`,
        method: "POST",
        data: params,
        auth: 0,
    });
}
// 添加本地oss文件
export function AddOssFile(params) {
    return request({
        url: `addOssFilebyVendor`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 添加本地oss文件
export function AddOssFileToregister(params) {
    return request({
        url: `addOssFile`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 获取oss列表
export function GetOssFileList(params) {
    return request({
        url: `getOssFileListbyVendor`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 获取上传凭证
export function GetVodeUpload(params) {
    return request({
        url: `getVodUploadbyVendor`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 获取视频点播列表
export function GetMyVodList(params) {
    return request({
        url: `getVodListbyVendor`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 获取过滤后的汇率列表
export function getExchateRateListCom(params) {
    return request({
        url: `getExchateRateListCombyVendor`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 新建用户和店铺
export function AddUserAndStore(params) {
    return request({
        url: `addUserAndStore`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 获取国际货币
export function GetExchateRateLists(params) {
    return request({
        url: `getExchateRateList`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 环信客服注册用户
export function RegisterEasemodUser(params) {
    return request({
        url: `registerEasemodUser`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 获取店铺下的im分组
export function GetImChatGroup(params) {
    return request({
        url: `getImChatGroupbyVendor`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 获取im记录
export function GetImChatList(params) {
    return request({
        url: `getImChatListbyVendor`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 添加客服回复通信记录
export function AddImChat(params) {
    return request({
        url: `addImChatbyVendor`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 获取城市列表
export function GetCityList(params) {
    return request({
        url: `getCityList`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 拉取城市列表
export function PullCityList(params) {
    return request({
        url: `pullCityList`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 拉取城市列表
export function GetAreabyAName(params) {
    return request({
        url: `getAreabyAName`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 获取订单支付列表
export function GetOrderPayList(params) {
    return request({
        url: `getOrderPayListbyVendor`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 获取国家列表
export function GeCountryV2List(params) {
    return request({
        url: `geCountryV2List`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 获取区域和城市列表
export function GetStateAndCityListByCountryId(params) {
    return request({
        url: `getStateAndCityListByCountryId`,
        method: "POST",
        data: params,
        auth: 0,
    });
}